import React from 'react';
import FormWrapper from "./FormWrapper";
import styles from "../../styles/authorization_forms/InfoContainer.module.css";
import {connect} from "react-redux";

function InfoContainer(props) {
    return (
        <>
            {
                props.loading && <FormWrapper><span>Loading...</span></FormWrapper>
            }
            {
                props.error !== '' &&
                <FormWrapper><span className={styles["error"]}>{props.error}</span></FormWrapper>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error
    }
}

export default connect(mapStateToProps)(InfoContainer);
import authStyles from "../../styles/authorization_forms/AuthorizationForm.module.css"
import React, {useState} from 'react'
import OuterServicesContainer from "./outer-services/OuterServicesContainer";
import AuthButton from "./AuthButton";
import FormWrapper from "./FormWrapper";
import {EMAIL_TYPE, LOGIN_TYPE, PHONE_TYPE} from "../../redux/auth/authTypes";
import {login} from "../../redux";
import {connect} from "react-redux";
import {Link, useSearchParams} from "react-router-dom";
import useConfig from "../../hooks/config";
import styles from "../../styles/authorization_forms/SelectTypeLogin.module.css";
import InfoContainer from "./InfoContainer";

function AuthorizationForm(props) {
    
    const [auth, setAuth] = useState({
        login: "",
        typeLogin: LOGIN_TYPE,
        password: "",
        hiddenPass: true
    })

    const [searchParams] = useSearchParams()
    const homePage = useConfig("homePage");

    const redirectPath = searchParams.get("redirect") || homePage;

    const submitHandler = (e) => {
        e.preventDefault();

        props.login(auth, () => {
            window.location.replace(redirectPath);
        })
    }

    const typePassword = auth.hiddenPass ? "password" : "text";
    const placeHolderLogin = EMAIL_TYPE === auth.typeLogin ? "Email 'example@email.com'" :
        LOGIN_TYPE === auth.typeLogin ? "Login" : "Phone";
    
    return (
        <div className={authStyles["Auth"]}>
            <h1 className={authStyles["Auth-header"]}>Authorization</h1>
            <form className={authStyles["form"]} onSubmit={submitHandler}>
                <FormWrapper title={"Type of login:"} htmlFor={"loginType"}>
                    <select className={styles["form-input-select"]} value={auth.typeLogin} onChange={e => setAuth({...auth, typeLogin: e.target.value})}
                            id="loginType">
                        <option value={LOGIN_TYPE}>Login</option>
                        <option value={EMAIL_TYPE}>Email</option>
                        <option value={PHONE_TYPE}>Phone</option>
                    </select>
                </FormWrapper>
                <FormWrapper title={"Login:"} htmlFor={"login"}>
                    <input type="text" name="login" className={authStyles["form-input-password-text"]} id="login"
                           placeholder={placeHolderLogin} value={auth.login}
                           onChange={e => setAuth({...auth, login: e.target.value})}/>
                </FormWrapper>
                <FormWrapper title={"Password:"} htmlFor={"password"}>
                    <input type={typePassword} name="password" className={authStyles["form-input-password-text"]}
                           id="password" placeholder={"Password"} value={auth.password}
                           onChange={e => setAuth({...auth, password: e.target.value})}/>
                </FormWrapper>
                <FormWrapper>
                    <input type="checkbox" name='reveal-password' id={authStyles["reveal-password"]}
                           checked={!auth.hiddenPass}
                           onChange={e => setAuth({...auth, hiddenPass: !e.target.checked})}/>
                    <span className={authStyles["secondary-text"]}>Reveal password</span>
                </FormWrapper>
                <InfoContainer/>
                <FormWrapper>
                <OuterServicesContainer className={authStyles["form-wrapper"]} />
                </FormWrapper>
                <FormWrapper style={{marginTop: "5rem"}}>
                    <AuthButton text="Log in"/>
                </FormWrapper>
                <FormWrapper style={{display: "flex", justifyContent: "center"}}>
                    <Link to={{pathname: "/registration", search: searchParams.get("redirect") && `?redirect=${encodeURIComponent(searchParams.get("redirect"))}`}} replace={true} className={authStyles["link-bottom"]}>I don't have an account</Link>
                </FormWrapper>
            </form>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        login : (authData, action) => dispatch(login(authData, action))
    }
}

export default connect(null, mapDispatchToProps)(AuthorizationForm);
import {SEND_LOGIN_FAILURE, SEND_LOGIN_REQUEST, SEND_LOGIN_SUCCESS} from "./authTypes";

const initialState = {
    loading: false,
    token: null,
    error: ''
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_LOGIN_REQUEST:
            return {
                token: null,
                loading: true,
                error: ''
            }
        case SEND_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                token: action.payload
            }
        case SEND_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}

export default authReducer;
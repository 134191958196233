import React from 'react'

import yandexButtonStyle from '../../../styles/authorization_forms/YandexAuthButton.module.css';
// import googleButtonStyle from '../../../styles/authorization_forms/GoogleAuthButton.module.css';
import outerContainerStyle from '../../../styles/authorization_forms/OuterServicesContainer.module.css';

import OAuthButton from './OAuthButton.jsx';
import {connect} from "react-redux";
import {redirectToYandex} from "../../../redux";

function OuterServicesContainer(props) {
    return (
        <div className={outerContainerStyle["outer-services-container"]}>
            <OAuthButton className={outerContainerStyle["outer-service"]} styles={yandexButtonStyle} text="Yandex" onClick={props.redirectYandex}/>
            {/*<OAuthButton className={outerContainerStyle["outer-service"]} styles={googleButtonStyle} text="Google"/>*/}
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        redirectYandex: () => {dispatch(redirectToYandex())}
    }
}

export default connect(null, mapDispatchToProps)(OuterServicesContainer);
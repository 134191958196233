import authStyles from "../../styles/authorization_forms/AuthorizationForm.module.css"
import React, {useState} from 'react'
import OuterServicesContainer from "./outer-services/OuterServicesContainer";
import AuthButton from "./AuthButton";
import FormWrapper from "./FormWrapper";
import {register} from "../../redux";
import {connect} from "react-redux";
import {Link, useSearchParams} from "react-router-dom";
import useConfig from "../../hooks/config";
import InfoContainer from "./InfoContainer";

function RegistrationForm(props) {

    const [auth, setAuth] = useState({
        login: "",
        password: "",
        hiddenPass: true,
        repeatPassword: "",
        name: ""
    })

    const [searchParams] = useSearchParams()
    const homePage = useConfig("homePage");

    const redirectPath = searchParams.get("redirect") || homePage;

    const submitHandler = (e) => {
        e.preventDefault();

        props.register(auth, () => {
            window.location.replace(redirectPath);
        })
    }

    const typePassword = auth.hiddenPass ? "password" : "text";
    return (
        <div className={authStyles["Auth"]}>
            <h1 className={authStyles["Auth-header"]}>Registration</h1>
            <form className={authStyles["form"]} onSubmit={submitHandler}>
                <FormWrapper title={"Login:"} htmlFor={"login"}>
                    <input type="text" name="login" className={authStyles["form-input-password-text"]} id="login"
                           placeholder={"Login"} value={auth.login}
                           onChange={e => setAuth({...auth, login: e.target.value})}/>
                </FormWrapper>
                <FormWrapper title={"Name:"} htmlFor={"name"}>
                    <input type="text" name="name" className={authStyles["form-input-password-text"]} id="name" value={auth.name} onChange={e => setAuth({...auth, name: e.target.value})} placeholder={"Name"}/>
                </FormWrapper>
                <FormWrapper title={"Password:"} htmlFor={"password"}>
                    <input type={typePassword} name="password" className={authStyles["form-input-password-text"]}
                           id="password" placeholder={"Password"} value={auth.password}
                           onChange={e => setAuth({...auth, password: e.target.value})}/>
                </FormWrapper>
                <FormWrapper title={"Repeat password:"} htmlFor={"repeatPassword"}>
                    <input type={typePassword} name="repeatPassword" className={authStyles["form-input-password-text"]}
                           id="repeatPassword" placeholder={"Repeat password"} value={auth.repeatPassword}
                           onChange={e => setAuth({...auth, repeatPassword: e.target.value})}/>
                </FormWrapper>
                <FormWrapper>
                    <input type="checkbox" name='reveal-password' id={authStyles["reveal-password"]}
                           checked={!auth.hiddenPass}
                           onChange={e => setAuth({...auth, hiddenPass: !e.target.checked})}/>
                    <span className={authStyles["secondary-text"]}>Reveal password</span>
                </FormWrapper>
                <InfoContainer/>
                <FormWrapper>
                    <OuterServicesContainer className={authStyles["form-wrapper"]}/>
                </FormWrapper>
                <FormWrapper style={{marginTop: "5rem"}}>
                    <AuthButton text="Register"/>
                </FormWrapper>
                <FormWrapper style={{display: "flex", justifyContent: "center"}}>
                    <Link to={{pathname: "/login", search: searchParams.get("redirect") && `?redirect=${encodeURIComponent(searchParams.get("redirect"))}`}} replace={true} className={authStyles["link-bottom"]}>I have an account</Link>
                </FormWrapper>
            </form>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        register: (authData, action) => dispatch(register(authData, action))
    }
}

export default connect(null, mapDispatchToProps)(RegistrationForm);
import React from 'react';
import styles from '../../styles/authorization_forms/BackgroundHOC.module.css';

function BackgroundHOC({children}) {
    return (
        <div className={styles.backgroundHOC}>
            {children}
        </div>
    );
}

export default BackgroundHOC;
import './App.css';
import AuthorizationForm from './components/authorization_forms/AuthorizationForm';
import {Route, Routes} from "react-router-dom";
import BackgroundHOC from "./components/authorization_forms/BackgroundHOC";
import {Provider} from "react-redux";
import store from "./redux/store";
import HomePage from "./components/HomePage";
import {loadTokenFromStorage} from "./redux";
import RegistrationForm from "./components/authorization_forms/RegistrationForm";
import OAuth from "./components/authorization_forms/OAuth";

function App() {
    store.dispatch(loadTokenFromStorage())
  return (
      <Provider store={store}>
          <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={
                  <BackgroundHOC>
                      <AuthorizationForm />
                  </BackgroundHOC>
              } />
              <Route path="/registration" element={
                  <BackgroundHOC>
                      <RegistrationForm />
                  </BackgroundHOC>
              } />

              <Route path="/oauth" element={
                  <BackgroundHOC>
                      <OAuth />
                  </BackgroundHOC>
              } />
          </Routes>
      </Provider>
  );
}

export default App;

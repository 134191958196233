import React from 'react'

function OAuthButton(props) {
    const {styles, className, text} = props;

    return (
        <button className={className} id={styles.button} onClick={props.onClick} type="button">
            <span className={styles.icon}></span>
            <span className={styles.text}>{text}</span>
        </button>
    )
}

export default OAuthButton
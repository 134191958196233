import {SEND_REDIRECT_URL_FAILURE, SEND_REDIRECT_URL_REQUEST, SEND_REDIRECT_URL_SUCCESS} from "./outerAuthTypes";

const initialState = {
    redirectUrl: null,
    error: "",
    loading: false
}

const outerAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_REDIRECT_URL_REQUEST:
            return {
                error: "",
                loading: true,
                redirectUrl: ""
            }
        case SEND_REDIRECT_URL_SUCCESS:
            return {
                ...state,
                redirectUrl: action.payload
            }
            case SEND_REDIRECT_URL_FAILURE:
                return {
                    ...state,
                    error: action.payload
                }
        default:
            return state;
    }
}

export default outerAuthReducer;
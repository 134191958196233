import React from 'react'

import styles from "../../styles/authorization_forms/AuthButton.module.css"

function AuthButton(props) {
    const {text} = props;

    return (
        <div id={styles["container-button"]}>
            <button type='submit' id={styles["button"]}>{text}</button>
        </div>
    )
}

export default AuthButton
import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

function HomePage(props) {
    return (
        <div>
            <h1>HomePage</h1>
            <div>
                <Link to={{pathname: "/login"}} >Login</Link>
            </div>
            <div>
                <Link to="/registration">Registration</Link>
            </div>
            <h2>{props.token && props.token.token}</h2>

        </div>
    );
}

const mapStateToProps = state => {
    return {
        token: state.auth.token
    }
}

export default connect(mapStateToProps)(HomePage);
import React from 'react';
import useConfig from "../../hooks/config";
import {useLocation} from "react-router-dom";
import axios from "axios";

function OAuth(props) {
    const location = useLocation();
    const callBackURI = useConfig("accountsHOST") + useConfig("callbackBaseUrl") + location.search;

    axios.get(callBackURI)
    .then(response => {
            console.log(response.data);
        })
    .catch(error => {
        console.log(error);
    })

    return (
        <div></div>
    );
}

export default OAuth;